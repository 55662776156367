* {
 margin: 0;
 padding: 0;
}

*,
::after,
::before {
 box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');


#main {
 background: url(./images/page-gardient.png) repeat-x #fff;
 min-height: 100vh;
 color: #404040;
 font-family: 'Poppins', sans-serif;
}
.row{
 --bs-gutter-x: 1.5rem;
 --bs-gutter-y: 0;
 display: flex;
 flex-wrap: wrap;
 margin-top: calc(-1 * var(--bs-gutter-y));
 margin-right: calc(-.5 * var(--bs-gutter-x));
 margin-left: calc(-.5 * var(--bs-gutter-x));

 
}
.col-md-4{
 flex-shrink: 0;
 flex: 0 0 auto;
 width: 33.33333333%;
 padding-right: calc(var(--bs-gutter-x) * .5);
 padding-left: calc(var(--bs-gutter-x) * .5);
}
.col-md-6{
 flex-shrink: 0;
 flex: 0 0 auto;
 width: 50%;
 padding-right: calc(var(--bs-gutter-x) * .5);
 padding-left: calc(var(--bs-gutter-x) * .5);
}
.ml-auto{
 margin-left: auto;
}
.mb-4{
 margin-bottom: 20px;
}
.align-items-center{
 align-items: center;
}
.backbtn{
 height: 30px; 
}
.iconbox{
 height: 60px;
 width: 60px;
 border-radius: 50%;
 overflow: hidden;
 display: flex;
 align-items: center;
 justify-content: center;
 background: #7fcecd;
}
.iconbox img{
 width: 35px;
}
.d-flex {
 display: flex;
}

.align-items-center {
 align-items: center;
}

.justify-content-center {
 justify-content: center;
}

.h-100 {
 height: 100vh;
}

.mt-4 {
 margin-top: 20px;
}

input[type="submit"], .btn {
 margin-top: 10px;
 cursor: pointer;
 font-size: 15px;
 background: #70bfbe;
 border: 1px solid #70bfbe;
 color: #fff;
 padding: 10px 20px;
 border-radius: 50px;
 text-decoration: none;
}
.btn-sm{
 padding: 5px 13px;
 font-size: 13px;
}

input[type="submit"]:hover {
 background: #395693;
}
.m-2{
 margin: 15px;
}
.form-group {
 margin-bottom: 20px;
}

.form-control {
 display: block;
 width: 100%;
 padding: 0.375rem 0.75rem;
 font-size: 1rem;
 font-weight: 400;
 line-height: 1.5;
 border: 1px solid #e1e1e1;
 outline: none;
 border-radius: 0.25rem;
}

.form-label {
 display: block;
 margin-bottom: 5px;
}

.text-center {
 text-align: center;
}

.card {
 background: #fff;
 border: none;
 box-shadow: 0px 0px 20px #00000014;
 border-radius: 20px;
}

.card-body {
 padding: 20px;
}

.loginform .card {
 width: 100%;
 max-width: 400px;
 margin: 10px;
}

.logo {
 max-width: 220px;
 margin: 10px auto;
}

/* dashboard */
.leftsidebar {
 width: 250px;
 background: #222b42;
 min-height: 100vh;
}

.leftsidebar .logo{
 padding: 10px;
}
.leftsidebar li a{
 text-decoration: none;
 color: #fff;
 font-weight: 500; 
 display: flex;
 align-items: center;
 gap: 10px;
 padding: 12px 10px;
 font-size: 16px;
}
.leftsidebar li a.active{ 
 background: #ffffff33;
}
.leftsidebar li a:hover{
 color: #7fcecd;
}
.leftsidebar li img{
 height: 30px;
}
.mainTitle{
 font-size: 20px;
 margin-bottom: 20px;
 color: #7fcecd;
 text-overflow: ellipsis;
 white-space: nowrap;
 overflow: hidden;
}

.maincontent {
 width: 100%;
 position: relative;
 padding-bottom: 100px;
}
.popertitem{
 background: #fff;
 border: none;
 box-shadow: 0px 0px 20px #00000014;
 border-radius: 20px;
 overflow: hidden;
 margin-bottom: 20px;
}
.popertitem img{
 width: 100%;
}
.result-price-area, .result-desc-area, .result-deals-area {
 margin-bottom: 15px;
}
.result-email-phone img, .result-rate-website img {
 width: 15px;
 margin: 6px 5px 6px 0;
 vertical-align: middle;
}
.result-price-area li{
 list-style-type: none;
}
.result-price-area li span {
 width: 15px;
 display: inline-block;
 vertical-align: middle;
 text-align: center;
 margin-right: 5px;
}
.result-price-area li img{
 width: 10px;

}
.result-contact-area span {
 margin-right: 10px;
}
.result-contact-area a {
 color:#000;
 text-decoration: none;
}
.result-details{
 padding: 15px;
 font-size: 12px;
}
.result-details h2 {
 font-size: 16px;
 color:#000;
 margin-bottom: 10px;
}
.mb-0{
 margin-bottom: 0;
}



/* footer */
footer{
 position: absolute;
 bottom: 0;
 left:0px;
 background: #ececec;
 width: 100%;
 display: flex;
 align-items: center;
 padding: 10px 15px;
 font-size: 11px;
 
}
footer a{
 color: #395693;
 text-decoration: none;
}
footer .socialicon{
 margin-left: auto;
 display: flex;
 gap: 10px;
}
.copyright span{
 display: block;
}
.emailfooter{
 margin: auto;
}
 

@media only screen and (max-width: 767px) {
  .leftsidebar ul{
    position: absolute;
    z-index: 1000;
    right: 10px;
    top: 15px;
    list-style-type: none;
  }
  .leftsidebar li a.active{
    display: none;
  }
  .dashboard > .d-flex{
    display: block;
  }
  .leftsidebar{
    width: 100%;
    min-height: auto;
  }
 .col-md-4{ 
  width: 100%; 
  margin-bottom: 15px;
 }
 .col-md-6{ 
  width: 100%; 
  margin-bottom: 15px;
 }
 footer{
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
    text-align: center;
 }
 footer .socialicon{
  margin: 5px auto;
 }
}
